<template>
  <div>
    <c-tab
      :dense="true"
      type="pagetop"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      align="left"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :param="popupParam"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
// import selectConfig from '@/js/selectConfig';
// import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'water-master-manage',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
          psiDocumentId: '',
          documentClassCd: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      splitter: { data: 280 },
      tab: 'tab1',
      tabItems: [
        { name: 'tab1', label: '1. 수질 방지시설', component: () => import(`${'./waterMaster01.vue'}`) },
        { name: 'tab2', label: '2. 수질 배출시설', component: () => import(`${'./waterMaster02.vue'}`) },
        { name: 'tab3', label: '3. 수질 전력량계', component: () => import(`${'./waterMaster03.vue'}`) },
        { name: 'tab4', label: '4. 공급수', component: () => import(`${'./waterMaster04.vue'}`) },
        { name: 'tab5', label: '5. 배출수', component: () => import(`${'./waterMaster05.vue'}`) },
        { name: 'tab6', label: '6. 검사항목 마스터', component: () => import(`${'./waterMaster06.vue'}`) },
        { name: 'tab6-1', label: '6-1. 사업장별 검사항목', component: () => import(`${'./waterMaster0601.vue'}`) },
        { name: 'tab7', label: '7. 측정위치', component: () => import(`${'./waterMaster07.vue'}`) },
        { name: 'tab8', label: '8. 원료/첨가제', component: () => import(`${'./waterMaster08.vue'}`) },
        { name: 'tab9', label: '9. 약품', component: () => import(`${'./waterMaster09.vue'}`) },
        { name: 'tab10', label: '10. 폭기조', component: () => import(`${'./waterMaster10.vue'}`) },
      ],
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getData();
    },
    getData() {
    },
  }
};
</script>